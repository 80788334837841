import sal from 'sal.js'

/**
 * Helpers
 */
import { onVisibilityChange, isDesktop, fromBreakpoint} from './helpers'

import uniqueUserID from './uniqueUserID'

/**
 * Web standards
 */
if (window.currenttheme === 'uwnieuwbouwwoning') {
  const menuv2 = import('./menuv2')
} else {
  const menu = import('./menu')
}
import './lazyload'
import './cookieconsent'
import './popup'
import './accordions'


// Price calculator tool
// import './calculator/utils/get.products'
if ( document.getElementById('calculator')) {
  const styleCalculator = import( '../scss/components/_calculator.scss' )
  const Calculator = import('./calculator/main')
  const Tooltips = import('./tooltips')
}

// Adviesrapport
if (document.getElementById('adviesrapport_app')) {
  const styleCalculator = import( '../scss/components/_calculator.scss' )
  const Adviesrapport = import('./adviesrapport/main')
}

/**
 * Dynamic import scripts based on elements
 */ 
const elements = [
  '#calculator',
  '[data-bs-toggle="tooltip"]',
  '[data-has-swiper="true"]',
  '#showroom',
  '.collapse__content',
  '.cases',
  '.cases-template',
  '.blogs-template',
  '.reviews-template',
  '.swiper',
  '.steps',
  '.brands-swiper',
  '.reviewsv2-swiper',
];

[].forEach.call(elements, (element) => {

  if (isDesktop()) {
    sal({
      // animate elements when they come into viewport for 25% with rootMargin
      rootMargin: '-10% 0%',
      threshold: 0.1,
      once: true,
      duration: 300
      // disabled: function() {
      //   return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
      // }
    });
  }


  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {
      if (element === '[data-bs-toggle="tooltip"]') {
        const Tooltips = import('./tooltips')
      }

      if (element === '[data-has-swiper="true"]') {
        const Swipers = import('./swipers')
      }

      if (element === '#showroom') {
        const styleShowroom = import( '../scss/components/_calculator.scss' )
        const Showroom = import('./appointment--general')
      }

      if (element === '.cases') {
        const Cases = import('./cases')
      }

      if (element === '.cases-template') {
        const Cases = import('./cases-archive')
      }

      if (element === '.blogs-template') {
        const Cases = import('./blogs-archive')
      }

      if (element === '.reviews-template') {
        const Cases = import('./reviews-archive')
      }

      if (element === '.brands-swiper') {
        const BrandsSwiper = import('./brands-swiper')
      }

      if (element === '.reviewsv2-swiper') {
        const ReviewsSwiper = import('./reviews-swiper')
      }

      if ( element === '.swiper' ) {
        const SwiperCSS = import( 'swiper/css' );
        // const SwiperCSSNavigation = import( 'swiper/css/navigation' );
        const SwiperCSSPagination = import( 'swiper/css/pagination' );
      }

     
    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});

// /**
//  * Dynamic scripts based on scroll
//  */
//  window.addEventListener('scroll', function(e) {
//   /** 
//   * From Tablet only
//   */
//   if ( fromBreakpoint('769px') === true ) {
//     if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
//       if (window.currenttheme !== 'uwnieuwbouwwoning') {
//         const Drift = import('./drift');
//       }
//     }
//   }
// });

/**
 * Smooth scroll
 */
document
  .querySelectorAll('averline[href^="#"]')
  .forEach(trigger => {
    trigger.onclick = function (e) {
      e.preventDefault();
      let hash = this.getAttribute('href');
      let target = document.querySelector(hash);
      if (target) {  // Check if the target exists
        let headerOffset = 150;
        let elementPosition = target.offsetTop;
        let offsetPosition = elementposition - offsetPosition;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    };
  });


  jQuery(function() {
    if (!$('body').hasClass('page-template-template-appointment')) {
      setTimeout(function() {
        if (location.hash) {
          let targetId = location.hash.split('#')[1];
          let targetElement = jQuery('#' + targetId);
          if (targetElement.length) {
            smoothScrollTo(targetElement);
          }
        }
      }, 1);
  
      function smoothScrollTo(target) {
        let headerOffset = 150;
        let elementPosition = target.offset().top;
        let offsetPosition = elementPosition - headerOffset;
  
        $('html, body').animate({
          scrollTop: offsetPosition
        }, 1000);
      }
    }
  });
